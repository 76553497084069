import Amplify, { API, graphqlOperation } from "aws-amplify"
import * as gql from './appsync-gql'

export default {

  async getCameraRecords (hostPropertyCode, minRecordStart, maxRecordStart) {
    return await API.graphql(
        graphqlOperation( gql.getCameraRecords, { hostPropertyCode, minRecordStart, maxRecordStart })
    ).catch(e => {
      console.error('getCameraRecords error:', e)
      return e
    })
  },
  async refreshProperty (hostId, propertyCode) {
    return await API.graphql(
        graphqlOperation( gql.refreshProperty, { hostId: hostId, propertyCode: propertyCode })
    ).catch(e => {
      console.error('refreshProperty error:', e)
      return e
    })
  },
  async getPropertyLocks (hostPropertyCode) {
    return await API.graphql(
        graphqlOperation( gql.getPropertyLocks, { hostPropertyCode: hostPropertyCode })
    ).catch(e => {
      console.error('getPropertyLocks error:', e)
      return e
    })
  },
  async getListings (hostId) {
    return await API.graphql(
      graphqlOperation(gql.getListings, { hostId: hostId })
    ).catch(e => {
      console.error('getListings error:', e)
      return e
    })
  },
  async createListing (input) {
    input.lastUpdateOn = (new Date).toISOString()

    // const gqlOpr = graphqlOperation(gql.createListing, { input })
    // console.log(gqlOpr, 'gqlOpr')

    return await API.graphql(
      graphqlOperation(gql.createListing, { input })
    ).catch(e => {
      console.error('createListing error:', e)
      return e
    })
  },
  async updateListing (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateListing, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateListing error:', e)
      return e
    })
  },
  async importReservations (input, pmsType) {

    return await API.graphql(
      graphqlOperation(gql.importReservations, { input, pmsType })
    ).catch(e => {
      console.error('importReservations error:', e)
      return e
    })
  },
  async importListings (input) {

    return await API.graphql(
      graphqlOperation(gql.importListings, { input })
    ).catch(e => {
      console.error('importListings error:', e)
      return e
    })
  },
  async listReservations (hostId, startDate, endDate, listingId) {
    return await API.graphql(
      graphqlOperation(gql.listReservations, { hostId, startDate, endDate, listingId })
    ).catch(e => {
      console.error('listReservations error:', e)
      return e
    })
  },
  async getReservations (listingId) {
    return await API.graphql(
      graphqlOperation(gql.getReservations, { listingId: listingId })
    ).catch(e => {
      console.error('getReservations error:', e)
      return e
    })
  },
  async getReservation (reservationCode, listingId) {
    return await API.graphql(
      graphqlOperation(gql.getReservation, { reservationCode: reservationCode, listingId: listingId })
    ).catch(e => {
      console.error('getReservation error:', e)
      return e
    })
  },
  onUpdateReservation (hostId, listingId) {
    return API.graphql(graphqlOperation(gql.onUpdateReservation, { hostId: hostId, listingId: listingId }))
  },
  async updateReservationStatus (input) {
    console.log('appsync.updateReservationStatus input', input)

    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateReservationStatus, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateReservationStatus error:', e)
      return e
    })
  },
  async removeReservation (input) {

    return await API.graphql(
      graphqlOperation(gql.removeReservation, { input })
    ).catch(e => {
      console.error('removeReservation error:', e)
      return e
    })
  },
  onRemoveReservation (hostId) {
    return API.graphql(graphqlOperation(gql.onRemoveReservation, { hostId: hostId }))
  },
  async generateReservation (input) {

    return await API.graphql(
      graphqlOperation(gql.generateReservation, { input })
    ).catch(e => {
      console.error('generateReservation error:', e)
      return e
    })
  },
  async updateIdVerified (input) {
    return await API.graphql(
        graphqlOperation(gql.updateIdVerified, { input })
      ).catch(e => {
        console.error('updateIdVerified', e)
        return e
      })
  },
  async notifyGuest (input, messageType) {
    return await API.graphql(
      graphqlOperation(gql.notifyGuest, { input, messageType })
    ).catch(e => {
      console.error('notifyGuest error:', e)
      return e
    })
  },
  async getMembers (reservationCode) {
    return await API.graphql(
        graphqlOperation(gql.getMembers, { reservationCode: reservationCode })
    ).catch(e => {
      console.error('getMembers error:', e)
      return e
    })
  },
  async updateMember (input) {
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateMember, { input })
    ).catch(e => {
      console.error('updateMember error:', e)
      return e
    })
  },
  async updateCamera (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    if (!input.isRecording) {
      input.isRecording = false
    }

    if (!input.isDetecting) {
      input.isDetecting = false
    }

    if (!input.onvif.isPullpoint) {
      input.onvif.isPullpoint = false
    }

    if (!input.onvif.isSubscription) {
      input.onvif.isSubscription = false
    }

    return await API.graphql(
      graphqlOperation(gql.updateCamera, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateCamera error:', e)
      return e
    })
  },
  async getCameras (hostPropertyCode) {
    console.log('getCameras hostPropertyCode', hostPropertyCode)
    
    return await API.graphql(
        graphqlOperation( gql.getCameras, { hostPropertyCode: hostPropertyCode })
    ).catch(e => {
      console.error('getCameras error:', e)
      return e
    })
  },
  async getScanners (hostPropertyCode) {
    console.log('getScanners hostPropertyCode', hostPropertyCode)
    
    return await API.graphql(
        graphqlOperation( gql.getScanners, { hostPropertyCode: hostPropertyCode })
    ).catch(e => {
      console.error('getScanners error:', e)
      return e
    })
  },
  async getCameaRecords (terminalKey) {
    return await API.graphql(
        graphqlOperation( gql.getCameaRecords, { terminalKey: terminalKey })
    ).catch(e => {
      console.error('getCameaRecords error:', e)
      return e
    })
  },
  async getCalendars (params) {
    console.log('getCalendars params', params)

    return await API.graphql(
      graphqlOperation( gql.getCalendars, params)
    ).catch(e => {
      console.error('getCalendars error:', e)
      return e
    })
  },
  async getHost (hostId) {
    console.log('getHost', hostId)
    
    return await API.graphql(
      graphqlOperation(gql.getHost, { hostId: hostId })
    ).catch(e => {
      console.error('getHost error:', e)
      return e
    })
  },
  async createHost (input) {
    console.log('createHost', input)

    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.createHost, { input })
    ).catch(e => {
      console.error('createHost error:', e)
      return e
    })
  },
  async updateHost (input) {
    const expectedLastUpdateOn = input.lastUpdateOn
    input.lastUpdateOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.updateHost, { input, expectedLastUpdateOn })
    ).catch(e => {
      console.error('updateHost', e)
      return e
    })
  },
  async verifyChannel (inviteCode, hostId) {
    return await API.graphql(
      graphqlOperation(gql.verifyChannel, { inviteCode, hostId })
    ).catch(e => {
      console.error('verifyChannel', e)
      return e
    })
  },
  async dispatchScannerDeployment (input) {
    console.log('dispatchScannerDeployment', input)

    input.lastRequestOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.dispatchScannerDeployment, { input })
    ).catch(e => {
      console.error('dispatchScannerDeployment', e)
      return e
    })
  },
  async resetScannerDeployment (input) {
    console.log('resetScannerDeployment', input)

    input.lastRequestOn = (new Date).toISOString()

    return await API.graphql(
      graphqlOperation(gql.resetScannerDeployment, { input })
    ).catch(e => {
      console.error('resetScannerDeployment', e)
      return e
    })
  },
  async refreshDevices (input) {
    console.log('refreshDevices', input)

    return await API.graphql(
      graphqlOperation(gql.refreshDevices, { input })
    ).catch(e => {
      console.error('refreshDevices', e)
      return e
    })
  },
  async updateHostPms (hostId, input) {
    console.log('updateHostPms', {hostId, input})

    return await API.graphql(
      graphqlOperation(gql.updateHostPms, { hostId, input })
    ).catch(e => {
      console.error('updateHostPms', e)
      return e
    })
  },  
  async initHostPms (input) {
    console.log('initHostPms', input)

    return await API.graphql(
      graphqlOperation(gql.initHostPms, { input })
    ).catch(e => {
      console.error('initHostPms', e)
      return e
    })
  },
  async getPmsList () {
    console.log('getPmsList')

    return await API.graphql(
      graphqlOperation(gql.getPmsList)
    ).catch(e => {
      console.error('getPmsList', e)
      return e
    })
  },
  async getHostPms (hostId) {
    console.log('getHostPms', hostId)

    return await API.graphql(
      graphqlOperation(gql.getHostPms, {hostId})
    ).catch(e => {
      console.error('getHostPms', e)
      return e
    })
  }
}