export const getListings = `query GetListings($hostId: String!) {
  getListings(hostId: $hostId) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    pmsType
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
    picture
    address {
      street
      zipcode
      city
      state
      apt
      latitude
      longitude
    }
  }
}
`

export const createListing = `mutation CreateListing($input: CreateListingInput!) {
  createListing(input: $input) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    pmsType
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
    picture
    address {
      street
      zipcode
      city
      state
      apt
      latitude
      longitude
    }
  }
}
`

export const updateListing = `mutation UpdateListing($input: UpdateListingInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateListing(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    pmsType
    active
    staticKeyItems {
      roomCode
      keyInfo
    }
    smartKeyItems {
      roomCode
      equipmentId
      equipmentName
    }
    checkInTime
    checkOutTime
    countryCode
    timezone
    lastUpdateOn
    picture
    address {
      street
      zipcode
      city
      state
      apt
      latitude
      longitude
    }
  }
}
`

export const importReservations = `mutation ImportReservations($input: ListingKeyInput!, $pmsType: String!) {
  importReservations(input: $input, pmsType: $pmsType) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const importListings = `mutation ImportListings($input: UpdateHostPmsInput!) {
  importListings(input: $input) {
    hostId
    propertyCode
    listingId
    listingName
    internalName
    needManualCheck
    guideUrl
    registeredOn
    pmsListingId
    pmsUserId
    pmsType
  }
}
`

export const listReservations = `query ListReservations($hostId: String!, $startDate: AWSDate!, $endDate: AWSDate!, $listingId: String) {
  listReservations(hostId: $hostId, startDate: $startDate, endDate: $endDate, listingId: $listingId) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const getReservations = `query GetReservations($listingId: String!) {
  getReservations(listingId: $listingId) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const getReservation = `query GetReservation($reservationCode: String!, $listingId: String!) {
  getReservation(reservationCode: $reservationCode, listingId: $listingId) {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const notifyGuest = `mutation NotifyGuest($input: MutateReservationInput!, $messageType: String!) {
  notifyGuest(input: $input, messageType: $messageType) {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const updateReservationStatus = `mutation UpdateReservationStatus($input: MutateReservationInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateReservationStatus(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`


export const onUpdateReservation = `subscription OnUpdateReservation($hostId: String!, $listingId: String) {
  onUpdateReservation(hostId: $hostId, listingId: $listingId)
  {
    reservationCode
    listingId
    hostId
    username
    email
    phoneNo
    bookingId
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const removeReservation = `mutation RemoveReservation($input: MutateReservationInput!) {
  removeReservation(input: $input)
  {
    hostId
    listingId
    reservationCode
  }
}
`


export const onRemoveReservation = `subscription OnRemoveReservation($hostId: String!) {
  onRemoveReservation(hostId: $hostId)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    referrer
    lastUpdateOn
  }
}
`

export const generateReservation = `mutation GenerateReservation($input: MutateReservationInput!) {
  generateReservation(input: $input)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const updateIdVerified = `mutation UpdateIdVerified($input: MutateReservationInput!) {
  updateIdVerified(input: $input)
  {
    hostId
    listingId
    reservationCode
    bookingId
    phoneNo
    username
    email
    guestName
    identityId
    memberCount
    checkInDate
    checkOutDate
    smartKeyItems {
      roomCode
      keyInfo
      equipmentId
    }
    isKeyNotified
    isIdVerified
    lastRequestOn
    lastResponse
    rejectReason
    referrer
    bookingStatus
    pmsType
    propertyCode
    guideUrl
    internalName
    needManualCheck
    lastUpdateOn
  }
}
`

export const getMembers = `query GetMembers($reservationCode: String!) {
  getMembers(reservationCode: $reservationCode) {
    reservationCode
    memberNo
    faceImgKey
    idImgKey
    checkInImgKey
    similarity
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    keyNotified
    lastUpdateOn
  }
}
`

export const updateMember = `mutation UpdateMember($input: UpdateMemberInput!) {
  updateMember(input: $input)
  {
    reservationCode
    memberNo
    faceImgKey
    idImgKey
    checkInImgKey
    similarity
    fullName
    gender
    birthDate
    address
    phoneNumber
    idNumber
    nationality
    occupation
    prevPlace
    nextDest
    memberKeyItem {
      roomCode
      keyInfo
    }
    keyNotified
    lastUpdateOn
  }
}
`

export const getCameras = `query GetCameras($hostPropertyCode: String!) {
  getCameras(hostPropertyCode: $hostPropertyCode) {
    hostId
    uuid
    propertyCode
    category
    equipmentId
    equipmentName
    localIp
    username
    password
    rtsp {
      port
      path
      codec
      framerate
    }
    onvif {
      port
      isSubscription
      isPullpoint
    }
    isRecording
    isDetecting
    isPlaying
    lastUpdateOn
  }
}
`

export const getScanners = `query GetScanners($hostPropertyCode: String!) {
  getScanners(hostPropertyCode: $hostPropertyCode) {
    uuid
    hostId
    propertyCode
    equipmentId
    equipmentName
    coreName
    localIp
    latitude
    longitude
    lastUpdateOn
  }
}
`

export const getCameaRecords = `query GetCameaRecords($equipmentId: String!) {
  getCameaRecords(equipmentId: $equipmentId) {
    equipmentId
    recordStart
    recordEnd
    hostId
    propertyCode
    hostPropertyCode
    scannerEquipmentId
    cameraIp
    videoKey
    snapshotKey
  }
}
`

export const updateCamera = `mutation UpdateCamera($input: UpdateCameraInput!, $expectedLastUpdateOn: AWSDateTime) {
  updateCamera(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    uuid
    propertyCode
    category
    equipmentId
    equipmentName
    localIp
    username
    password
    rtsp {
      port
      path
      codec
      framerate
    }
    onvif {
      port
      isSubscription
      isPullpoint
    }
    isRecording
    isDetecting
    isPlaying
    lastUpdateOn
  }
}
`

export const getCalendars = `query GetCalendars($listingId: String!, $checkInDate: String!, $checkOutDate: String!, $oldCheckInDate: String, $oldCheckOutDate: String) {
  getCalendars(listingId: $listingId, checkInDate: $checkInDate, checkOutDate: $checkOutDate, oldCheckInDate: $oldCheckInDate, oldCheckOutDate: $oldCheckOutDate) {
    reservedDate
  }
}
`

export const getHost = `query GetHost($hostId: String!) {
  getHost(hostId: $hostId) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const createHost = `mutation CreateHost($input: CreateHostInput!) {
  createHost(input: $input) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const updateHost = `mutation UpdateHost($input: UpdateHostInput!, $expectedLastUpdateOn: AWSDateTime!) {
  updateHost(input: $input, expectedLastUpdateOn: $expectedLastUpdateOn) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const verifyChannel = `mutation VerifyChannel($hostId: String!, $inviteCode: String!) {
  verifyChannel(hostId: $hostId, inviteCode: $inviteCode) {
    hostId
    identityId
    email
    smartlockProperty {
      smartlockVerified
      smartlockHost
      smartlockAuth
      resourceServerIdentifier
      clientId
      clientSecret
    }
    pmsUserId
    lastUpdateOn
  }
}
`

export const dispatchScannerDeployment = `mutation DispatchScannerDeployment($input: ScannerDeploymentInput!) {
  dispatchScannerDeployment(input: $input) {
    hostId
    listingId
    reservationCode
    lastRequestOn
  }
}
`

export const resetScannerDeployment = `mutation ResetScannerDeployment($input: ScannerDeploymentInput!) {
  resetScannerDeployment(input: $input) {
    reservationCode
    listingId
    hostId
    lastRequestOn
  }
}
`
export const refreshDevices = `mutation RefreshDevices($input: RefreshDevicesInput!) {
  refreshDevices(input: $input) {
    propertyCode
    roomCode
    equipmentId
    equipmentName
    withKeypad
  }
}
`

export const getPropertyLocks = `query GetPropertyLocks($hostPropertyCode: String!) {
  getPropertyLocks(hostPropertyCode: $hostPropertyCode) {
    propertyCode
    roomCode
    equipmentId
    equipmentName
    withKeypad
  }
}
`
/*
export const getOfflinePassword = `query GetOfflinePassword($input: GetPasswordInput!) {
  getOfflinePassword(input: $input) {
    deviceId
    effectiveTime
    invalidTime
    password
  }
}
`

export const getTempPassword = `query GetTempPassword($input: GetPasswordInput!) {
  getTempPassword(input: $input) {
    deviceId
    effectiveTime
    invalidTime
    password
  }
}
`
*/

export const refreshProperty = `mutation RefreshProperty($hostId: String!, $propertyCode: String!) {
  refreshProperty(hostId: $hostId, propertyCode: $propertyCode)
}
`

export const updateHostPms = `mutation updateHostPms($hostId: String!, $input: [UpdateHostPmsInput]) {
  updateHostPms(hostId: $hostId, input: $input) {
    hostId
    pmsType
    pmsUserId
    channelId
  }
}
`

export const initHostPms = `mutation InitHostPms($input: InitHostPmsInput!) {
  initHostPms(input: $input) {
    returnUrl
    expiresAt
  }
}
`

export const getPmsList = `query GetPmsList {
  getPmsList {
    pmsType
    verified
  }
}
`

export const getHostPms = `query GetHostPms($hostId: String!) {
  getHostPms(hostId: $hostId) {
    hostId
    pmsType
    pmsUserId
    channelId
  }
}
`
export const getCameraRecords = `query GetCameraRecords($hostPropertyCode: String!, $minRecordStart: AWSDateTime!, $maxRecordStart: AWSDateTime!) {
  getCameraRecords(hostPropertyCode: $hostPropertyCode, minRecordStart: $minRecordStart, maxRecordStart: $maxRecordStart) {
    equipmentId
    equipmentName
    recordStart
    recordEnd
    hostId
    propertyCode
    hostPropertyCode
    scannerEquipmentId
    cameraIp
    videoKey
    snapshotKey
  }
}
`
