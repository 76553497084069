import Vue from 'vue'
import Router from 'vue-router'
import { components, AmplifyEventBus } from 'aws-amplify-vue'
import store from '@/store'
import appsync from '@/api/appsync'
import { AppSyncSubscriber } from '@/utils'


// import Listings from '@/views/Listings.vue'
const Listings = () => import('@/views/Listings.vue')
// import Reservations from '@/views/Reservations.vue'
const Reservations = () => import('@/views/Reservations.vue')
// import Members from '@/views/Members.vue'
const Members = () => import('@/views/Members.vue')

const About = () => import('@/views/About.vue')

const Scanners = () => import('@/views/Scanners.vue')

const Cameras = () => import('@/views/Cameras.vue')

const CameraRecords = () => import('@/views/CameraRecords.vue')

const Host = () => import('@/views/Host.vue')

const Calendars = () => import('@/views/Calendars.vue')

Vue.use(Router)

AmplifyEventBus.$on('authState', async (state) => {
  // console.log('AmplifyEventBus.$on', state)

  if (state === 'signedOut'){
    console.log('signedOut')
    // console.log('store.state.session.hostId', store.state.session.hostId)
    // AppSyncSubscriber.disconnect('onCreateNotification')

    router.push({path: '/auth'})
  } else if (state === 'signedIn') {
    console.log('signedIn')

    const error = await store.dispatch('session/login').catch(e => {
      console.error('login!!!', e)
      return e
    })

    if (error) {
      window.alert(JSON.stringify(error))
      await store.dispatch('session/logout').catch(e => console.error('login!!!', e))
      return
    }

    router.push({path: '/listings'})
  }
})

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/listings',
      name: 'listings',
      component: Listings,
      alias: '/',
      meta: { requiresAuth: true }
    },
    {
      path: '/reservations',
      name: 'reservations',
      component: Reservations,
      meta: { requiresAuth: true }
    },
    {
      path: '/members',
      name: 'members',
      component: Members,
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      component: About,
      meta: { requiresAuth: true }
    },
    {
      path: '/scanners',
      name: 'scanners',
      component: Scanners,
      meta: { requiresAuth: true }
    },
    {
      path: '/cameraRecords',
      name: 'cameraRecords',
      component: CameraRecords,
      meta: { requiresAuth: true }
    },
    {
      path: '/cameras',
      name: 'cameras',
      component: Cameras,
      meta: { requiresAuth: true }
    },
    {
      path: '/host',
      name: 'host',
      component: Host,
      meta: { requiresAuth: true }
    },
    {
      path: '/calendars',
      name: 'calendars',
      component: Calendars,
      meta: { requiresAuth: true }
    },
    {
      path: '/auth',
      name: 'Authenticator',
      component: components.Authenticator,
    },
  ]
})

router.beforeEach(async (to, from, next) => {

  // console.log("beforeEach store.state.session", JSON.stringify(store.state.session))
  // console.log("beforeEach store.state.host",store.state.host)

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (!store.state.session.hostId) {
      await store.dispatch('session/logout')
        .catch(e => console.error('login!!!', e))
    }

    return next()
  }

  return next()
})

router.afterEach((to, from) => {

  // console.log('afterEach', to)
  // console.log('router.options.routes', router.options.routes)

  if (router.options.routes.find(route => route.path === to.path || to.path === route.alias)){
    if (to.path === '/auth') {
      return
    } else {
      // AppSyncSubscriber.connect('host', 'onCreateNotification', 'loadNotifications', 'addNotification')
    }
  }

})

export default router