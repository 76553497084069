import appsync from '@/api/appsync'
import router from '@/router'

const state = {
  listing: null,
  scanners: [],
  cameras: [],
  cameraRecords: [],
  nowTime: new Date(),
  terminalKey: null
}

const actions = {
  gotoCameraRecords ({ commit }, val) {
    console.log('gotoCameraRecords', val)

    commit('setListing', val)

    router.push({path: '/cameraRecords'})
  },
  gotoCameras ({ commit }, val) {
    console.log('gotoCameras', val)

    commit('setListing', val)

    router.push({path: '/cameras'})
  },
  gotoScanners ({ commit }, val) {
    console.log('gotoScanners', val)

    commit('setListing', val)

    router.push({path: '/scanners'})
  },
  async getCameraRecords ({ commit, state, rootState }, val) {

    const rtn = await appsync.getCameraRecords(`${state.listing.hostId}-${state.listing.propertyCode}`, val.minRecordStart, val.maxRecordStart)

    console.log('getCameraRecords', JSON.stringify(rtn.data.getCameraRecords))

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const cameraRecords = rtn.data.getCameraRecords.map((rawCameraRecord, index) => {
        let subject = ''
        if (rawCameraRecord.snapshotKey) {
          subject = `${rawCameraRecord.cameraIp}-guest`
        } else {
          if (((new Date(rawCameraRecord.recordEnd) - new Date(rawCameraRecord.recordStart)) / 1000) <= 1) {
            subject = `${rawCameraRecord.cameraIp}-badvideo`
          } else {
            subject = rawCameraRecord.cameraIp
          }
        }

        return {
          // id: `${rawCameraRecord.equipmentId}-${index}`,
          id: index,
          // description: rawCameraRecord.description,
          location: rawCameraRecord.propertyCode,
          subject: subject,
          // calendar: rawCameraRecord.equipmentName,
          calendar: rawCameraRecord.cameraIp,
          start: new Date(rawCameraRecord.recordStart),
          end: new Date(rawCameraRecord.recordEnd),
          videoKey: rawCameraRecord.videoKey,
          snapshotKey: rawCameraRecord.snapshotKey
        }
      })

      commit('setCameraRecords', cameraRecords)
    }

  },
  async updateCamera ({ commit, state }, val) {
    console.log('updateCamera state.cameras', val.params)

    const rtn = await appsync.updateCamera(val.params)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const camera = rtn.data.updateCamera

      Object.assign(state.cameras[val.index], camera)

      console.log('updateCamera state.cameras', state.cameras)

      return
    }
  },
  async loadCameras ({ commit, state, rootState }) {

    const rtn = await appsync.getCameras(`${state.listing.hostId}-${state.listing.propertyCode}`)
    console.log('getCameras', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const cameras = rtn.data.getCameras

      commit('setCameras', cameras)
    }
  },
  async loadScanners ({ commit, state, rootState }) {

    const rtn = await appsync.getScanners(`${state.listing.hostId}-${state.listing.propertyCode}`)
    console.log('getScanners', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const scanners = rtn.data.getScanners

      commit('setScanners', scanners)
    }
  },
  async validateScanners ({ commit, state, rootState }, propertyCode) {

    const rtn = await appsync.getScanners(`${rootState.session.hostId}-${propertyCode}`)
    console.log('validateScanners', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      const scanners = rtn.data.getScanners

      if (scanners.length == 0) {
        throw new Error('Scanner not found!')
      }
    }
  },
  async dispatchScannerDeployment ({ commit, state, rootState }, val) {

    val.hostId = rootState.session.hostId;

    const rtn = await appsync.dispatchScannerDeployment(val)
    console.log('dispatchScannerDeployment', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      return rtn.data.dispatchScannerDeployment
    }
  },
  async resetScannerDeployment ({ commit, state, rootState }, val) {

    val.hostId = rootState.session.hostId;

    const rtn = await appsync.resetScannerDeployment(val)
    console.log('resetScannerDeployment', rtn)

    if (rtn.errors) {
      throw rtn.errors[0]
    } else {
      return rtn.data.resetScannerDeployment
    }
  },
  refreshScanners ({ state }, scanner) {
  
    const index = state.scanners.findIndex((crt, crtIndex) => crt.terminalKey == scanner.terminalKey)

    if (index > -1) {
      Object.assign(state.scanners[index], scanner)
    } else {
      state.scanners.push(scanner)
    }

  },
  // refreshScanRecords ({ state }, scanRecord) {
  
  //   state.scanRecords.push(scanRecord)
  // },
  startTimer ({ commit }) {        
    setInterval(() => { 
      commit('setNowTime', new Date())
    }, 1000)        
  },
}

const mutations = {
  setScanners(state, val) {
    state.scanners = val
  },
  setCameras(state, val) {
    state.cameras = val
  },  
  setCameraRecords(state, val) {
    state.cameraRecords = val
  },
  setTerminalKey(state, val) {
    state.terminalKey = val
  },
  setNowTime(state, val) {
    state.nowTime = val
  },
  setListing(state, val) {
    state.listing = val
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}